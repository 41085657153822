:root {
  --mainborderradius:8px;
  --secondaryborderradius:4px;

  --blurple: 	#5865F2;
  --white:#fff;
  --lightgrey:#303136;
  --medlightgrey:#ddd;

  --grey:#30313666;
  --errorred:#ff5656;

  --mainred:#ff3232;

  --black:#000;
  --darkgrey:#1c1c1f;
  --meddarkgrey:#232428;
  --bottomnavactionchosencolor:var(--blurple);
  --bottomnavactionchosencolorbg:	#5865F211;

  --componentloadingbg:var(--medlightgrey);
  --spotifygreen:#1DB954;
  --bodybg:var(--darkgrey);
  --bottomnav:var(--meddarkgrey);
  --containerbg:var(--meddarkgrey);
  --topbar:var(--meddarkgrey);
  --profileimagesizesecondary:80px;
  --profileimagesizetertiary:45px;

  --inputsize:40px;
  --inputradius:20px;
  --inputcolor:var(--grey);
  --fontcolor:var(--medlightgrey);
  --prifontcolor:#f9f9f9;
  
  --h1fontsize:45px;
  --h15fontsize:25px;
   --h2fontsize:20px;
  --h3fontsize:15px;
  --h4fontsize:12px;
  --labelfontsize:13px;
  --buttonfontsize:15px;
  --iconsize:15px;
  --actionicons:20px;
  --pfontsize:15px;
  --bordercolor:var(--lightgrey);
  --contmaxwidth:880px;
  --postcontmaxwidth:600px;
  --transparentbg:#00000088;
  --bottomnavactionsize:25px;
}


html,
body {
  padding: 0;
  margin: 0;
  background: var(--bodybg);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    
}
body{
  overflow-y:scroll;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
   user-select: none;
   
}


a {
  color:var(--blurple);
  cursor: pointer;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}


@keyframes placeholderShimmer{
  0%{
    background:var(--containerbg);
  }
  50%{
    background:var(--bordercolor);
  }
  100%{
    background:var(--containerbg);
  }
}

#nprogress .peg {
  display: none;
  opacity: 0!important;
}



